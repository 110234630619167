@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background: #FFFFFE;
}
.paraLineHight{
    line-height: 2.6rem !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f1f5f9 inset !important;
}